navbar .navigation .navbar-nav .nav-link:hover,
.navbar .navigation .navbar-nav .active .nav-link,
.navbar.is-sticky .navbar-nav .active .nav-link,
.navbar.is-sticky .navbar-nav.nav-light .nav-link:hover,
.navbar.is-sticky .navbar-nav.nav-light .active .nav-link,
.navbar .navigation .navbar-nav .active .nav-link {
  --tw-text-opacity: 1;
  /* color: rgb(97, 12, 234 / var(--tw-text-opacity)); */
  color: rgb(97, 12, 234);
}

.timeline .timeline-item .date-label-left::after,
.timeline .timeline-item .duration-right::after {
  background-color: rgb(126, 34, 206);
}

p[class*="expand"] {
  max-height: 100%;
  overflow: visible;
}

p[class*="hide"] {
  max-height: 23.2svh;
  overflow: hidden;
}

.quotes {
  position: relative;
  isolation: isolate;
}

.quotes::before {
  content: open-quote;
  font-family: serif;
  font-weight: 900;
  font-size: 7rem;
  color: #e0dfdf;
  position: absolute;
  left: -1rem;
  top: 0.7rem;
  z-index: -1;
}

.quotes:is(.dark .dark\:text-slate-200)::before {
  color: hsl(0, 0%, 29%) !important;
}

.quotes::after {
  content: no-close-quote;
}

/* div > img.prefer-light {
  background: #eaeffd !important;
} */

.read-more-button {
  color: rgb(126, 34, 206);
  cursor: pointer;
}

.mobile-only{
  display: none;
}

@media screen and (max-width:30rem) {
  .mobile-only{
   display: block;
  }
}
